

@font-face {
  font-family: 'Titillium Web';
  src:  url('/assets/fonts/Titillium Web.woff2') format('woff2'),
}
@font-face {
  font-family: 'NotoSerifSC';
  src:  url('/assets/fonts/NotoSerifSC-Regular.otf') format('opentype'),
}


body {
  background-color: #fff;
  padding:0px;
  font: 20.0px/1.5 Titillium Web; 
  color:#000000;
  font-weight:400;
  margin:0
}

.social-icons a{display:inline-block;height:2rem;width:2rem;background-color:#495057;color:#fff!important;border-radius:100%;text-align:center;font-size:1.2rem;line-height:2.2rem;margin-right:1rem}.social-icons a:last-child{margin-right:0}.social-icons a:hover{background-color:#000000}

autocolor{
  color:#000000;
}

lightonly {
  visibility: visible;
}

darkonly {
  visibility: hidden;
}

education {
    font-weight: 500;
    font-size: 115%;
  }
papertitle {
    font-weight: 600;
    font-size: 100%;
  }

@media (prefers-color-scheme: dark) {
    body {
        background-color: #20212b;
        color: #dadbdf;
    }

    autocolor{
      color:#dadbdf;
    }

    lightonly {
      visibility: hidden;
    }

    darkonly {
      visibility: visible;
    }

    papertitle {
    color:#eeeff0;
    }

}

h1 {
  font-weight: 600;
  color:#000000;
  margin:0 0 20px;
}


h3, h4, h5, h6 {
  font-weight: 500;
  color:#000000;
  margin:0 0 20px;
}

h2 {
  color:#000000;
  margin:2px 0px 15px;
  font-size: 140%;
}

@media (prefers-color-scheme: dark) {
  h2, h3, h4, h5, h6 {
    color:#fff;
  }

  h1 {
    color:rgb(62, 183, 240);
  }
}

p, ul, ol, table, pre, dl {
  margin:0 0 20px;
}

h1, h2, h3 {
  line-height:1.1;
}

h1 {
  font-size:28px;
}

a {
  color:#1953e6;
  text-decoration: underline;
  -webkit-text-decoration-color: #d1ddfa; /* safari still uses vendor prefix */
  text-decoration-color: #d1ddfa;

}

a:hover {
  color:#39c;
}

@media (prefers-color-scheme: dark) {
  a {
    color:rgb(62, 183, 240);
    text-decoration: underline;
    -webkit-text-decoration-color: #085477; /* safari still uses vendor prefix */
    text-decoration-color: #085477;

  }
}

a small {
  font-size:11px;
  color:#777;
  margin-top:-0.3em;
  display:block;
}

a:hover small {
  color:#777;
}

.wrapper {
  width:1070px;
  margin:0 auto;
}

blockquote {
  border-left:1px solid #e5e5e5;
  margin:0;
  padding:0 0 0 20px;
  font-style:italic;
}

code, pre {
  font-family:Monaco, Bitstream Vera Sans Mono, Lucida Console, Terminal, Consolas, Liberation Mono, DejaVu Sans Mono, Courier New, monospace;
  color:#333;
}

pre {
  max-width:500px;
  padding:8px 15px;
  background: #f8f8f8;
  border-radius:5px;
  border:1px solid #e5e5e5;
  overflow-x: auto;
}

table {
  width:100%;
  border-collapse:collapse;
}

th, td {
  text-align:left;
  padding:5px 10px;
  border-bottom:1px solid #e5e5e5;
}

dt {
  color:#444;
  font-weight:500;
}

th {
  color:#444;
}

@media (prefers-color-scheme: dark) {
  th {
    color:rgb(62, 183, 240);
  }

  dt {
    color:rgb(62, 183, 240);
  }
}

img {
  max-width:100%;
}

header {
  width:220px;
  float:left;
  position:fixed;
  -webkit-font-smoothing:subpixel-antialiased;
  padding-top:4.0em;
  text-align:center
}


b {
  font-weight: 400;
  color:#1953e6;

}


@media (prefers-color-scheme: dark) {
  b {
    font-weight: 400;
    color:rgb(62, 183, 240);
  
  }
  
  }


header ul {
  list-style:none;
  height:40px;
  padding:0;
  background: #f4f4f4;
  border-radius:5px;
  border:1px solid #e0e0e0;
  width:270px;
}

header li {
  width:89px;
  float:left;
  border-right:1px solid #e0e0e0;
  height:40px;
}

header li:first-child a {
  border-radius:5px 0 0 5px;
}

header li:last-child a {
  border-radius:0 5px 5px 0;
}

header ul a {
  line-height:1;
  font-size:11px;
  color:#999;
  display:block;
  text-align:center;
  padding-top:6px;
  height:34px;
}

header ul a:hover {
  color:#999;
}

header ul a:active {
  background-color:#f0f0f0;
}

strong {
  color:#222;
  font-weight:500;
}

@media (prefers-color-scheme: dark) {
  strong {
    color:#eeeff0;
  }
}

header ul li + li + li {
  border-right:none;
  width:89px;
}

header ul a strong {
  font-size:14px;
  display:block;
  color:#222;
}

section {
  width:750px;
  float:right;
  padding-top:4.0em;
  padding-bottom:50px;
}

small {
  font-size:11px;
}

hr {
  border:0;
  background:#e5e5e5;
  height:1px;
  margin:0 0 20px;
}

footer {
  width:220px;
  float:left;
  position:fixed;
  bottom:30px;
  -webkit-font-smoothing:subpixel-antialiased;
  text-align:center;
}

@media print, screen and (max-width: 1070px) {

  div.wrapper {
    width:auto;
    margin:0;
  }


  header, section, footer {
    float:none;
    position:static;
    width:auto;
  }

  header {
    padding-right:320px;
  }

  section {
    border:1px solid #e5e5e5;
    border-width:1px 0;
    padding:20px 0;
    margin:0 0 20px;
  }

  header a small {
    display:inline;
  }

  header ul {
    position:absolute;
    right:50px;
    top:52px;
  }


   .image.avatar img {
        border-radius: 100%;
        width: 150px;
        padding:20px;
      }
  #head1o2 {
    visibility: hidden;
  }
      
  #head2o2 {
    visibility: hidden;
  }
  
}

@media print, screen and (max-width: 1070px) {
  body {
    word-wrap:break-word;
    padding:15px;
  }

  header {
    padding:0;
  }


  pre, code {
    word-wrap:normal;
  }
}

@media print, screen and (max-width: 480px) {
  body {
    padding:15px;
  }

  header ul {
    width:99%;
  }

  header li, header ul li + li + li {
    width:33%;
  }

  .image.avatar img {
      border-radius: 100%;
      width: 120px;
      padding:20px;
  }
  #head1o2 {
    visibility: hidden;
  }
  
  #head2o2 {
    visibility: hidden;
  }

}

@media print {
  body {
    padding:0.4in;
    font-size:12pt;
    color:#444;
  }
}

.biblink {
  text-decoration:none;
  display: inline;
  margin-right: 0px;
}

.fakelink {
  text-decoration: none;
  cursor: pointer;
}

.bibref {
  font-size: 70%;
  margin-top: 10px;
  margin-left: 0px;
  display:none;
  font-family: monospace;
}
